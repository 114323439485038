<template>
  <div id="app">
  <router-view />
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
body {
	margin: 0;
}
/* flex 布局 */
.d-flex { display:flex;justify-content:center;align-items: center; }
.flex{display:flex;}
.flex-row{ flex-direction:row!important; }
.flex-column{ flex-direction:column!important; }
.flex-row-reverse{ flex-direction:row-reverse!important; }
.flex-column-reverse{ flex-direction:column-reverse!important; }
.flex-wrap{ flex-wrap:wrap;}
.flex-nowrap{ flex-wrap:nowrap;}
.justify-start{justify-content:flex-start;}
.justify-end{justify-content:flex-end;}
.justify-between{justify-content:space-between;}
.justify-center{justify-content:center;}
.align-center{ align-items: center; }
.align-stretch{ align-items: stretch; }
.align-start{ align-items: flex-start; }
.align-end{ align-items: flex-end; }
.flex-1{ flex: 1; }
.flex-2{ flex: 2; }
.flex-3{ flex: 3; }
.flex-4{ flex: 4; }
.flex-5{ flex: 5; }
</style>

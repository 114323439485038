import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('./views/index/index.vue')
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('./views/index/index.vue')
    },
	{
	  path: '/ProductService2',
	  name: 'ProductService2',
	  component: () => import('./views/ProductService/ProductService2.vue')
	},
	{
	  path: '/ProductService3',
	  name: 'ProductService3',
	  component: () => import('./views/ProductService/ProductService3.vue')
	},
	{
	  path: '/login',
	  name: 'login',
	  component: () => import('./views/login.vue')
	},
	{
	  path: '/settleIn',
	  name: 'settleIn',
	  component: () => import('./views/settleIn.vue')
	}
  ]
})
